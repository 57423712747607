import React from "react"
import Layout from "../components/layout"

const Privacy = () => {
  return (
    <Layout>
      <div className="text-page">
        <h2 className="has-no-margin">
          Privacy statement bij de Bibliotheek 2025
        </h2>
        <p>
          De Bibliotheek 2025 is een pilot project van BiSC en Cubiss. De
          Bibliotheek 2025 website wordt beheerd door BiSC.
        </p>
        <h5 className="has-no-margin">Cookies:</h5>
        <p>Deze site gebruikt geen niet-functionele cookies.</p>
        <h4 className="has-no-margin">
          Privacyverklaring Website Bibliotheek 2025
        </h4>
        <strong>Datum: 1 juni 2021</strong>
        <br></br>
        <br></br>
        <h5 className="has-no-margin">Inleiding</h5>
        <p>
          BiSC is een serviceorganisatie voor de bibliotheeksector. Zij biedt
          ondersteunende diensten en advies aan bibliotheken in de provincie
          Utrecht.
        </p>
        <p>
          BiSC vindt de bescherming van persoonsgegevens zeer belangrijk,
          respecteert uw privacy en draagt er zorg voor dat de persoonlijke
          informatie die u aan BiSC verschaft, altijd vertrouwelijk, zorgvuldig
          en veilig wordt behandeld.
        </p>
        <p>
          BiSC hanteert hierbij regelgeving zoals vastgelegd in de Algemene
          Verordening Gegevensbescherming (AVG). De Autoriteit Persoonsgegevens
          (AP) houdt toezicht op de naleving van deze wet. BiSC handelt binnen
          de kaders van de wet AVG en maakt daarvoor onder andere gebruik van
          deze Privacyverklaring.
        </p>
        <h5 className="has-no-margin">Opbouw Privacyverklaring</h5>
        <p>
          In de inleiding van dit document vindt u informatie over BiSC. In de
          onderstaande paragrafen beschrijven we welke persoonsgegevens we
          verwerken in relatie tot onze website en voor welke doelen. Ook
          lichten we toe op welke grondslag we dit mogen doen. Het delen van
          gegevens met andere partijen is belicht, evenals het verwerken van
          persoonsgegevens buiten de EU. De beveiliging van persoonsgegevens
          komt aan bod samen met het behandelen van de bewaartermijnen. Als
          laatste is een onderdeel toegevoegd over rechten die u als relatie
          heeft en de mogelijkheid om een klacht in te dienen of bij vragen
          contact op te nemen met BiSC.
        </p>
        <h5 className="has-no-margin">Wat zijn persoonsgegevens?</h5>
        <p>
          Persoonsgegevens zijn alle gegevens die herleidbaar zijn tot een
          (levend) persoon. Soms aggregeren of anonimiseren we uw
          persoonsgegevens zodat u niet meer herleidbaar bent als persoon.
        </p>
        <h5 className="has-no-margin">
          Moet ik persoonsgegevens verstrekken aan BiSC?
        </h5>
        <p>
          Indien u zich registreert via onze website en/of via een
          contactformulier contact met ons opneemt is het noodzakelijk om uw
          contactgegevens vast te leggen. Indien u geen contactgegevens
          achterlaat dan kunnen wij eventuele vragen niet behandelen en u geen
          nieuwsbrief verzenden.
        </p>
        <h5 className="has-no-margin">
          Wie is de verwerkingsverantwoordelijke voor de verwerking van
          persoonsgegevens?
        </h5>
        <p>
          BiSC is de verwerkingsverantwoordelijke voor de verwerking van
          persoonsgegevens van ingezonden publicaties.
        </p>
        <h5 className="has-no-margin">
          Welke persoonsgegevens gebruiken wij voor onze diensten en producten?
        </h5>
        <p>
          Als je contact met ons opneemt via email slaan we de email op.
          Ingestuurde ideeën voor de bibliotheek van 2025 worden na goedkeuring
          gepubliceerd op de site zonder emailadres en naam.
        </p>
        <h5 className="has-no-margin">
          Voor welke doelen gebruiken we uw gegevens?
        </h5>
        <p>
          Wij verwerken de genoemde persoonsgegevens voor toezending van de
          nieuwsbrief van BiSC en voor beantwoording van vragen die u stelt via
          de website.
        </p>
        <p>
          Het anderszins verwerken van uw persoonsgegevens gebeurt alleen
          wanneer u BiSC daarvoor ondubbelzinnig toestemming heeft verleend.
        </p>
        <h5 className="has-no-margin">
          Op basis van welke grondslagen gebruiken we uw gegevens?
        </h5>
        <strong>4.1 Overeenkomst</strong>
        <p>
          BiSC beschouwt uw aanmelding voor de nieuwsbrief als een overeenkomst
          voor het ontvangen hiervan. Indien u dit niet wenst dan kunt u zich
          afmelden.
        </p>
        <p>
          BiSC beschouwt gegevens verstrekt via het contactformulier als
          tijdelijke overeenkomst gedurende de correspondentie om uw vra(a)g(en)
          en/of opmerking(en) af te kunnen handelen.
        </p>
        <h5 className="has-no-margin">
          Bestaan van geautomatiseerde individuele besluitvorming
        </h5>
        <p>
          BiSC maakt geen gebruik van geautomatiseerde individuele
          besluitvorming.
        </p>
        <h5 className="has-no-margin">
          Deelt BiSC gegevens met andere partijen?
        </h5>
        <p>
          Uw gegevens zijn bij ons in vertrouwde handen. Gegevens worden alleen
          intern gebruikt door BiSC of ondernemingen gelieerd aan BiSC. Er zijn
          echter enkele situaties waarin wel doorgifte kan plaatsvinden,
          namelijk bij door ons ingeschakelde hulppersonen of op grond van
          wettelijke verplichtingen.
        </p>
        <h5 className="has-no-margin">Door ons ingeschakelde hulppersonen</h5>
        <p>
          Het komt voor dat wij een externe partij inschakelen om bepaalde
          verwerkingen voor ons uit te voeren (bijv. het verzorgen van een
          mailing). In die situaties zullen we steeds met die externe partij(en)
          afspraken maken over het gebruik, de beveiliging en de geheimhouding
          van gegevens (“verwerkersovereenkomst”). Het komt erop neer dat deze
          externe partij(en) uw gegevens nooit voor hun eigen doeleinden mogen
          gebruiken.
        </p>
        <h5 className="has-no-margin">
          Afgifte op grond van wettelijke verplichtingen
        </h5>
        <p>
          Onder bepaalde omstandigheden zijn wij wettelijk verplicht uw gegevens
          af te geven aan overheidsinstanties (zoals politie/justitie, fiscus,
          etc.). Wij zullen uw gegevens alleen afgeven wanneer wij daartoe
          wettelijk verplicht zijn. Het is ons niet altijd toegestaan u te
          informeren over een dergelijke afgifte.
        </p>
        <h5 className="has-no-margin">
          Worden uw gegevens buiten de EU gebracht?
        </h5>
        <p>
          BiSC heeft als uitgangspunt persoonsgegevens alleen binnen de EU te
          verwerken. Aangezien de privacyverordening van toepassing is binnen de
          EU, kan daarmee in beginsel altijd een passend beschermingsniveau
          verzekerd worden.
        </p>
        <p>
          Er wordt gebruik gemaakt van onderstaande derde partij voor hosting
          van de website:
        </p>
        <ul>
          <li>
            <p>Hosting partij Netlify - Voor hosten van de website</p>
            <p>Land: Amerika</p>
            <p>Cookies: Hier worden geen Cookies verwerkt en of gebruikt.</p>
            <p>
              Gegevens die verwerkt worden: Gegevens om te voorzien in hosting
              zoals IP adressen.
            </p>
            <p>
              Privacyverklaring: zie{" "}
              <a
                href="https://netlify.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                netlify.com/privacy
              </a>
            </p>
            <p>Verwerkersovereenkomst: Ja, zie privacy/gdpr pagina</p>
          </li>
        </ul>

        <h5 className="has-no-margin">Hoe beveiligen we uw gegevens?</h5>
        <p>
          Omdat BiSC de beveiliging van persoonsgegevens erg belangrijk vindt,
          heeft BiSC een strikt beleid als het hier om gaat. BiSC hanteert
          verschillende technische en organisatorische maatregelen om ervoor te
          zorgen dat de persoonsgegevens niet misbruikt kunnen worden of op een
          andere manier in handen van de verkeerde persoon terecht komen.
        </p>
        <h5 className="has-no-margin">Hoe lang bewaren we uw gegevens?</h5>
        <p>
          Gegevens voor de verzending van de nieuwsbrief bewaren we zolang u bij
          ons ingeschreven staat voor de nieuwsbrief. Deze worden verwijderd
          zodra u zich afmeldt van de nieuwsbrief.
        </p>
        <p>
          Gegevens verkregen via het contactformulier bewaren wij gedurende de
          contactperiode. Daarna worden deze periodiek verwijderd.
        </p>
        <h5 className="has-no-margin">
          Welke rechten hebt u op basis van de verwerking van persoonsgegevens?
        </h5>
        <p>
          BiSC vindt het belangrijk dat u als relatie van BiSC de rechten op
          basis van de wet goed kunt uitoefenen. Daarvoor kunt u contact met
          BiSC opnemen. Hierna zal BiSC de uitvoering van onderstaande rechten
          afhandelen binnen de wettelijke termijn. Het betreft de volgende
          rechten:
        </p>
        <p>
          Het recht van inzage: u hebt het recht om inzage te krijgen in welke
          persoonsgegevens wij van u verwerken.
        </p>
        <p>
          Het recht van correctie: indien de persoonsgegevens die wij van u
          verwerken niet juist zijn, hebt u het recht om deze aan te laten
          passen.
        </p>
        <p>
          Het recht van verwijdering: indien wij uw persoonsgegevens niet meer
          nodig hebben voor het doel waarvoor we ze hebben gekregen, hebt u het
          recht te vragen deze te verwijderen.
        </p>
        <p>
          Het recht van beperking: gedurende de periode dat we bezig zijn om te
          bepalen of uw gegevens gerectificeerd moeten worden, het bepalen van
          de onrechtmatigheid van gegevensverwerking, het bepalen of gegevens
          verwijderd moeten worden of u bezwaar hebt ingediend tegen de
          verwerking, hebt u het recht om de beperking van de verwerking aan te
          vragen bij BiSC.
        </p>
        <p>
          Het recht van dataportabiliteit: op uw verzoek moeten wij alle
          persoonsgegevens die wij van u hebben overdragen naar andere
          organisaties. U kunt alleen van dit recht gebruik maken indien de
          gegevens worden verwerkt op grond van toestemming of overeenkomst;
        </p>
        <p>
          Het recht van bezwaar: indien wij gegevens verwerken op grond van
          gerechtvaardigd belang of algemeen belang, is het mogelijk bezwaar te
          maken waarna een belangenafweging door BiSC zal volgen.
        </p>
        <p>
          Komt u er niet uit of wilt u graag extra informatie over het inroepen
          van uw rechten dan kunt u contact opnemen via het contactformulier op
          de website.
        </p>
        <h5 className="has-no-margin">
          Klacht indienen bij de bevoegde autoriteit: Autoriteit
          Persoonsgegevens
        </h5>
        <p>
          BiSC vindt het belangrijk om tevreden relaties te hebben. Ook al doen
          wij er alles aan om dit na te streven, kan het voorkomen dat u als
          klant van BiSC niet tevreden bent. U kunt een klacht indienen bij de
          Autoriteit Persoonsgegevens, indien het gaat om de bescherming van
          persoonsgegevens. Dit kan via:
          <a
            href="https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons
          </a>
        </p>
        <h5 className="has-no-margin">Wijzigingen in deze Privacyverklaring</h5>
        <p>
          Deze Privacyverklaring kan worden gewijzigd. Deze wijzigingen worden
          bekendgemaakt op deze de website van BiSC. BiSC kan uw
          persoonsgegevens verwerken voor nieuwe doeleinden die nog niet staan
          vermeld in deze privacyverklaring. In dat geval zullen wij contact met
          u opnemen alvorens uw gegevens te gebruiken voor deze nieuwe
          doeleinden, om u op de hoogte te stellen van de wijzigingen aan ons
          reglement voor de bescherming van persoonlijke gegevens en om u de
          kans te bieden uw deelname in te trekken.
        </p>
        <h5 className="has-no-margin">Hoe kunt u contact met ons opnemen?</h5>
        <p>
          Mocht u nog vragen hebben, een klacht hebben of een opmerking hebben,
          dan kunt u contact opnemen via het contactformulier op onze website
          <a
            href="https://www.biscutrecht.nl"
            target="_blank"
            rel="noopener noreferrer"
          >
            (https://www.biscutrecht.nl)
          </a>
          .
        </p>
        <p>
          Als u ons uw telefoonnummer meedeelt via de website, zullen wij alleen
          telefonisch contact met u opnemen als dit nodig is om u te informeren
          over de melding die u hebt ingediend of de informatie die u online
          heeft aangevraagd.
        </p>
      </div>
    </Layout>
  )
}

export default Privacy
